<template>
  <div class="bg-white">
    <div class="flex items-center mb-1 mobile:flex-col">
      <h2 class="mr-4 mobile:mb-2">Märkused</h2>
      <button
        @click="addTodoModalActive = true"
        class="alt-button-green mobile:mb-2"
      >
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa märkus</span>
      </button>
    </div>

    <div
      class="flex flex-wrap"
      v-if="todoLoaded"
      :class="todo.length > 0 ? '-mx-1' : 'mobile:justify-center'"
    >
      <company-todo-card
        v-for="note in todo"
        :key="note.id"
        :todo="note"
        @toggleActiveTodo="toggleActiveTodo"
      ></company-todo-card>
      <div v-if="todo.length === 0" class="py-3 px-0 flex">
        <span>Ettevõttel puuduvad märkused </span>
      </div>
    </div>
    <add-company-todo-modal
      v-if="
        addTodoModalActive && todoLoaded && $store.state.companyData.retrieved
      "
      :company="$store.state.companyData.activeCompany"
      @closeModal="modalClosedHandler"
    ></add-company-todo-modal>
    <company-todo-popup-modal
      v-if="
        detailTodoViewActive && todoLoaded && $store.state.companyData.retrieved
      "
      :todo-id="activeTodo"
      :file-types="fileTypes"
      :company-uuid="$store.state.companyData.activeCompany.uuid"
      :user-role="$store.state.companyData.activeCompany.worker_permission"
      @closeModal="popupClosedHandler"
      @deleteModal="modalClosedHandler"
    >
    </company-todo-popup-modal>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import AddCompanyTodoModal from "@/components/company/todo/AddCompanyTodoModal";
import CompanyTodoCard from "@/components/company/todo/TodoCard";
import CompanyTodoPopupModal from "@/components/company/todo/TodoModal";

const fileTypesConst = [
  { key: "application/pdf", icon: "/icons/filetypes/pdf.svg" },
  {
    key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icon: "/icons/filetypes/xls.svg"
  },
  {
    key: "application/json",
    icon: "/icons/filetypes/json-file.svg"
  },
  {
    key: "image/png",
    icon: "/icons/filetypes/png.svg"
  },
  {
    key: "application/zip",
    icon: "/icons/filetypes/zip.svg"
  },
  {
    key: "text/html",
    icon: "/icons/filetypes/html.svg"
  },
  {
    key: "image/jpeg",
    icon: "/icons/filetypes/jpg.svg"
  },
  {
    key: "text/csv",
    icon: "/icons/filetypes/csv.svg"
  },
  {
    key: "application/gzip",
    icon: "/icons/filetypes/zip.svg"
  },
  {
    key:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    icon: "/icons/filetypes/ppt.svg"
  },
  {
    key: "application/x-msdos-program",
    icon: "/icons/filetypes/exe.svg"
  },
  {
    key: "application/vnd.oasis.opendocument.text",
    icon: "/icons/filetypes/odt.svg"
  },
  {
    key: "application/octet-stream",
    icon: "/icons/filetypes/e_id_logo.svg"
  }
];

export default {
  name: "CompanyTodo",
  components: { CompanyTodoPopupModal, CompanyTodoCard, AddCompanyTodoModal },
  mixins: [RequestHandler],
  props: {
    userRole: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      todo: [],
      todoLoaded: false,
      addTodoModalActive: false,
      detailTodoViewActive: false,
      activeTodo: null,
      fileTypes: []
    };
  },
  methods: {
    loadCompanyTodo() {
      this.fileTypes = fileTypesConst;
      this.todoLoaded = false;
      this.apiRequest(
        "notes/" + this.$store.state.companyData.activeCompany.uuid + "/",
        "get",
        true
      )
        .then(res => {
          if (res.status === 200) {
            this.$store.state.loading = false;
            this.todo = res.data;
            this.todoLoaded = true;
          } else if ({ res }.res.response.status === 403) {
            this.todoLoaded = true;
          }
        })
        .catch(error => {
          const errObj = { error };
          if (errObj) {
            this.todoLoaded = true;
          }
        });
    },
    popupClosedHandler() {
      this.$store.state.loading = false;
      this.detailTodoViewActive = false;
      this.activeTodo = null;
    },
    modalClosedHandler() {
      this.$store.state.loading = false;

      this.detailTodoViewActive = false;
      this.activeTodo = null;
      this.addTodoModalActive = false;
      this.loadCompanyTodo();
    },
    toggleActiveTodo(todo) {
      if (this.activeTodo === todo) {
        this.activeTodo = null;
        this.detailTodoViewActive = false;
      } else {
        this.activeTodo = todo;
        this.detailTodoViewActive = true;
      }
    }
  },
  mounted() {
    this.loadCompanyTodo();
  }
};
</script>

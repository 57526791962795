<template>
  <div
    class="flex flex-col w-3/12 mobile:w-full p-1 "
    @click="toggleActiveTodo"
  >
    <div
      class="rounded-xl shadow p-3 bg-offyellow cursor-pointer hover:shadow-outlinedark duration-75"
    >
      <div class="flex flex-row">
        <div class="flex flex-col w-9/12">
          <div v-if="todo.title">
            <h3 class="text font-medium text-xl">{{ todo.title }}</h3>
          </div>
          <span
            class="text-break"
            :class="todo.content.length >= 128 ? 'hidden' : ''"
            >{{ todo.content }}</span
          >
        </div>
        <div class="flex-row flex items-center w-3/12 justify-around">
          <div v-if="todo.file_counts.files > 0">
            <img src="/new/paperclip.svg" class="w-8 h-8" />
          </div>
          <div v-if="todo.file_counts.images > 0">
            <img src="/new/pildid2.svg" class="w-8 h-8" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CompanyTodoCard",
  props: {
    todo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    toggleActiveTodo() {
      this.$emit("toggleActiveTodo", this.todo.id);
    }
  },
  computed: {
    parseCreatedDate() {
      return moment(this.todo.created_at).format("MMMM Do YYYY, HH:mm:ss");
    }
  }
};
</script>

<template>
  <todo></todo>
</template>

<script>
import Todo from "@/components/company/Todo";
export default {
  components: {
    Todo
  }
};
</script>

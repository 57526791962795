<template>
  <div class="modal w-8/12" v-if="todoLoaded">
    <div
      class="modal-body modal-medium bg-white rounded-md flex items-center flex-col mobile:pb-12 p-4"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="flex flex-col w-full">
        <div class="flex justify-between items-center mb-1 mobile:flex-col">
          <div class="flex flex-col">
            <span
              class="text-xl font-semibold"
              :class="
                todo.title.indexOf(' ') >= 0 ? 'break-words' : 'break-all'
              "
              >Pealkiri: {{ todo.title }}</span
            >
            <span class="font-medium"
              >Autor: {{ todo.author.display_username }}</span
            >
          </div>
          <div class="flex">
            <div v-if="['R0', 'R1', 'R2'].includes(userRole)">
              <button
                @click="isConfirmDeleteModalActive = true"
                class="new-button-danger"
              >
                <span class="icon"
                  ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
                /></span>
                <span class="label">Kustuta märkus</span>
              </button>
            </div>
          </div>
        </div>
        <span
          v-if="todo.content !== ''"
          class="bg-offyellow rounded-gwdhalf flex-grow flex p-3"
          :class="todo.content.indexOf(' ') >= 0 ? 'break-words' : 'break-all'"
          >{{ todo.content }}</span
        >
        <span
          v-if="todo.content === ''"
          class="bg-offyellow rounded-gwdhalf flex-grow flex p-3"
        >
          Kirjeldus puudub
        </span>
      </div>
      <div v-if="todo.document.length > 0" class="flex flex-row w-full py-3">
        <div class="w-full flex flex-row flex-wrap">
          <project-file
            v-for="file in todo.document"
            :key="file.id"
            :company="$store.state.companyData.activeCompany.uuid"
            :file="file"
            :user-role="
              $store.state.companyData.activeCompany.worker_permission
            "
            @reloadFiles="loadTodo"
            @expandFile="openSidebar"
            @closeFile="closeSidebar"
            :current-expanded="expandedFile ? expandedFile.id : null"
          >
          </project-file>
        </div>
      </div>
      <delete-confirm-modal
        text="Olete kindel, et soovite valitud märkuse kustutada?"
        v-if="isConfirmDeleteModalActive"
        @closeModal="isConfirmDeleteModalActive = false"
        @confirmDelete="deleteTodo()"
      >
      </delete-confirm-modal>
      <div
        v-if="activeImage"
        class="modal overflow-x-hidden"
        @click="activeImage = null"
      >
        <div class="image-modal overflow-x-hidden">
          <img :src="activeImage" class="modal-image border-2 border-green" />
          <div
            @click="activeImage = null"
            class="close-button leading-none bg-danger flex justify-center items-center"
          >
            <span class="text-4xl typcn typcn-times leading-none"></span>
          </div>
        </div>
      </div>
      <file-modal
        v-if="$store.state.fileViewer.displayFileModal"
        :file="$store.state.fileViewer.fileToDisplay"
        :company="$store.state.fileViewer.company"
        :type="'companyfile'"
        @fileDeleted="loadTodo"
      ></file-modal>
      <div class="flex mt-4">
        <button class="new-button-danger" @click="closeModal">
          <span class="icon typcn typcn-times"></span>
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectFile from "@/components/project/ProjectFile";
import RequestHandler from "@/assets/mixins/RequestHandler";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal";
import { directive as onClickaway } from "vue-clickaway2";
import FileModal from "@/components/reusable/FileModal";
export default {
  name: "CompanyTodoPopupModal",
  components: {
    FileModal,
    DeleteConfirmModal,
    ProjectFile
  },
  props: {
    todoId: {
      type: Number,
      default: null
    },
    fileTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    companyUuid: {
      type: String,
      default: ""
    },
    userRole: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sidebarActive: false,
      expandedFile: null,
      imageToDisplay: null,
      imageModalActive: false,
      isConfirmDeleteModalActive: false,
      activeImage: null,
      todoLoaded: false,
      todo: null
    };
  },
  directives: {
    onClickaway: onClickaway
  },
  mixins: [RequestHandler],

  methods: {
    loadTodo() {
      this.todo = null;
      this.todoLoaded = false;
      this.apiRequest(
        "notes/" + this.companyUuid + "/" + this.todoId + "/",
        "get",
        true
      ).then(res => {
        this.todo = res.data;
        this.todoLoaded = true;
      });
    },
    activateImage(e) {
      if (this.activeImage === null) {
        this.activeImage = e;
      } else if (this.activeImage === e) {
        this.activeImage = null;
      } else {
        this.activeImage = e;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    openSidebar(e) {
      this.sidebarActive = true;
      this.expandedFile = e;
    },
    closeSidebar() {
      this.sidebarActive = false;
      this.expandedFile = null;
    },
    openImageModal(image) {
      this.imageToDisplay = {
        objectUrl: this.mediaUrl + image,
        relativePath: image
      };
      this.imageModalActive = true;
    },
    closeImageModal() {
      this.imageToDisplay = null;
      this.imageModalActive = false;
    },
    handleImageToggle(imageId) {
      if (this.imagesToKeep.indexOf(imageId) === -1) {
        this.imagesToKeep.push(imageId);
      } else {
        this.imagesToKeep.splice(this.imagesToKeep.indexOf(imageId), 1);
      }
    },
    deleteTodo() {
      this.apiRequest(
        "notes/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.todo.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res) {
          this.$emit("deleteModal");
        }
      });
    }
  },
  mounted() {
    this.loadTodo();
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    }
  }
};
</script>

<template>
  <div class="modal bg-offyellow">
    <div
      class="modal-body bg-offyellow modal-medium flex items-center flex-col overflow-y-scroll mobile:pb-12 px-4 py-8"
      v-on-clickaway:mousedown="closeModal"
    >
      <span class="text-black font-semibold text-2xl">Loo märkus</span>
      <div class="p-3 w-full ml-3 rounded">
        <div class="flex flex-row w-full">
          <div class="flex flex-col w-full">
            <input
              type="text"
              class="shadow focus:shadow-outline flex p-3 mb-3"
              v-model="todoTitle"
              placeholder="Pealkiri"
              :class="
                $v.$error && $v.todoTitle.$invalid
                  ? 'shadow-dangeroutline focus:shadow-dangeroutline'
                  : ''
              "
            />
            <textarea
              type="text"
              class="shadow focus:shadow-outline flex p-3 mb-3"
              v-model="todoContent"
              placeholder="Sisu"
              rows="4"
              :class="
                $v.$error && $v.todoContent.$invalid
                  ? 'shadow-dangeroutline focus:shadow-dangeroutline'
                  : ''
              "
            />
          </div>
          <div class="flex-col ml-6 hidden">
            <div class="flex flex-col p-3">
              <span class="text-black font-semibold text-xl"
                >Määra teavituse saamise aeg</span
              >
              <input type="date" />
            </div>
          </div>
        </div>
        <h3>Pildid/Failid/dokumendid</h3>
        <input
          type="file"
          multiple
          class="mb-3 bg-offwhite w-full"
          ref="todoFiles"
        />

        <div class="items-center justify-center w-full flex">
          <button @click="createTodo" class="new-button-green mr-2">
            <span class="typcn typcn-tick icon"></span>
            <span class="label">Salvesta</span>
          </button>
          <button @click="closeModal()" class="new-button-danger ml-2">
            <span class="icon typcn typcn-times"></span>
            <span class="label">Välju</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import { directive as onClickaway } from "vue-clickaway2";
import { maxLength } from "vuelidate/lib/validators";

export default {
  name: "AddCompanyTodoModal",
  mixins: [RequestHandler],
  data() {
    return {
      todoTitle: "",
      todoContent: "",
      todoID: null,
      close: false
    };
  },
  props: {
    company: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  directives: {
    onClickaway: onClickaway
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    createTodo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.loading = true;
        let data = {
          title: this.todoTitle,
          content: this.todoContent
        };
        this.apiRequest(
          "notes/" + this.company.uuid + "/new/",
          "post",
          true,
          data
        ).then(res => {
          if (res.status === 201) {
            this.addFilesToTodo(res.data.note_id);
            this.$store.state.loading = false;
            this.closeModal();
          } else if ({ res }.res.response.status === 403) {
            this.$store.state.loading = false;
          }
        });
      }
    },
    addFilesToTodo(noteID) {
      if (this.$refs.todoFiles.files.length > 0) {
        const filesToUpload = new FormData();
        for (let i = 0; i < this.$refs.todoFiles.files.length; i++) {
          const file = this.$refs.todoFiles.files[i];
          const fileToAppend = new File([file], file.name, { type: file.type });
          filesToUpload.append("files", fileToAppend, file.name);
        }
        this.apiRequest(
          "notes/" + this.company.uuid + "/" + noteID + "/new/",
          "patch",
          true,
          filesToUpload
        ).then(res => {
          res;
        });
      }
    }
  },
  validations: {
    todoTitle: {
      maxLength: maxLength(128)
    },
    todoContent: {
      maxLength: maxLength(1024)
    }
  }
};
</script>
